import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";

import { PetSpecies } from "../../../../../settings/pet";
import { ProductId } from "../../../../../settings/products";
import { assertNever } from "../../../../../utils/types";
import { Product } from "../../../domain/product";
import * as styles from "./CheckoutProductsList.module.scss";

interface CheckoutProductsListProps {
  products: Product[];
  species: PetSpecies;
  adoptionClassName?: string;
}

const CheckoutProductsList = ({
  products,
  species,
  adoptionClassName,
}: CheckoutProductsListProps): ReactElement => {
  const { t } = useTranslation();

  const getStyleClassForProduct = (product: ProductId): string => {
    switch (product) {
      case ProductId.healthBasic:
      case ProductId.healthUnlimited:
        return (species === PetSpecies.cat ? styles.healthCat : styles.healthDog) as string;
      case ProductId.prevention:
        return styles.prevention as string;
      case ProductId.glExtended:
        return styles.gl as string;
      default:
        assertNever(product);

        return "";
    }
  };

  return (
    <ul className={cn(styles.checkoutProductsList, adoptionClassName)}>
      {products
        .sort((a, b): number => (a.price > b.price ? -1 : 1))
        .map((product: Product, i) => (
          <li key={i}>
            <div className={cn(styles.product, getStyleClassForProduct(product.id))}>
              <div className={styles.productDecoration}></div>
              <p className={styles.productName}>{product.name}</p>
              <p className={styles.productDescription}>
                {t(`checkout_summary.products_list.${product.id}_description`)}
              </p>
            </div>
          </li>
        ))}
    </ul>
  );
};

export default CheckoutProductsList;
